<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp; Production Delivery List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="delivery_list_table"
             class="table table-bordered"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="_id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_date" data-width="100" data-formatter="dateFormatter" >Date</th>
          <th data-field="doc_no" data-width="100" >Doc No</th>
          <th data-field="ref_date" data-width="100" data-formatter="dateFormatter" >Ref Date</th>
          <th data-field="pro.name" >Process</th>
          <th data-field="remarks" >Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Delete</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Clickable menu -->
    <!--<ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">-->
    <!--<li>-->
    <!--<a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">-->
    <!--<i class="fab-icon-open icon-plus3"></i>-->
    <!--<i class="fab-icon-close icon-plus3"></i>-->
    <!--</a>-->
    <!--</li>-->
    <!--</ul>-->
    <!-- /clickable menu -->

    <vmodal name="invoice-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @before-open="beforeOpen" @before-close="beforeClose" >
      <DeliveryForm v-bind:mytrans="trans" ></DeliveryForm>
    </vmodal>

  </div>

</template>

<script>

  import DeliveryForm from '@/components/v1/tms/productiondelivery/ProductionDeliveryForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'ProductionDeliveryView',
    components: {
      DeliveryForm,
      FabButton
    },
    store,
    data () {
      return {
        series: 602,
        mytable: {},
        trans: JSON.parse('{"code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"tax_type":0,"gst_rate":0,"eway_bil_no":"","eway_bil_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"net_amount":0,"list":[]}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#delivery_list_table');
      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument(id);
          }else if ($(e.target).text() === 'Delete') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#delivery_list_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal() {
        this.$modal.hide('invoice-window');
      },
      beforeOpen(){

      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.$modal.show('invoice-window');
      },

      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.trans = JSON.parse('{"code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"tax_type":0,"gst_rate":0,"eway_bil_no":"","eway_bil_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"net_amount":0,"list":[]}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            if(resp.data != null){
              self.$data.trans = resp.data;
              self.$data.trans.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
              self.$data.trans.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');

              self.$modal.show('invoice-window');
            }

          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.trans.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.trans)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('invoice-window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${store.state.user.finyear}/${self.$data.series}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      }
    }

  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
